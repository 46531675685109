import Cookies from "js-cookie";
import {Modal} from "bootstrap";


const CevizPopup = function () {
    let popup;
    jQuery.fn.exists = function () {
        return this.length > 0;
    }

    function CreateModal(content) {
        let id = '#cevizPopup';
        let element = document.getElementById(id);
        if (typeof (element) != 'undefined' && element != null) {
            $(id).find("#ModalContent").html(content);
        } else {
            $('body').append('<div class="modal fade" id="' + id + '" tabindex="-1">\n' +
                '  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl popup-modal">\n' +
                '    <div class="modal-content">\n' +
                '      <div class="modal-header border-0">\n' +
                '        <h5 class="modal-title" id="ModalLabel"></h5>\n' +
                '        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Kapat"></button>\n' +
                '      </div>\n' +
                '      <div class="modal-body" id="popupModal"><div class="page-content">\n' + content +
                '      </div>\n' +
                '      </div>\n' +
                '    </div>\n' +
                '  </div>\n' +
                '</div>');
            element = document.getElementById(id);
        }
        return new Modal(element, {});
    }

    const showPopup = () => {
        const popupModal = CreateModal(popup.content);
        popupModal.show();

        var date = new Date();
        date.setTime(date.getTime() + (600 * 1000));

        if (popup.showType == 1)
            Cookies.set('cevizPopup', "true", {expires: date});

        if (popup.activeTime != 0)
            setTimeout(() => { popupModal.hide(); }, popup.activeTime * 1000);
    }

    const run = () => {

        if (popup.showTime == 0)
            showPopup();

        if (popup.showTime != 0)
            setTimeout(() => { showPopup(); }, popup.showTime * 1000);


    }


    return {
        init: function() {
            popup = window.cvzpopup;

            if (typeof (popup) != 'undefined' && popup != null){
                run();
            }




        }
    };
}();


jQuery(document).ready(function() {

    CevizPopup.init();

});