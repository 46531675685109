import Swiper, {Navigation, Pagination, Lazy, Autoplay, EffectCreative, Thumbs} from "swiper";
import Swal from 'sweetalert2'
import 'animate.css';
import SwiperAnimation from '@cycjimmy/swiper-animation';
import ma5menu from "./ma5-menu";
import {Modal} from 'bootstrap';
import Cookies from 'js-cookie';
import './_home_world';
import AOS from "aos";

jQuery.fn.exists = function () {
    return this.length > 0;
}

Swiper.use([Navigation, Pagination, Lazy, EffectCreative, Autoplay, Thumbs]);
const swiperAnimation = new SwiperAnimation();
const home_slider = new Swiper('.banner .swiper', {
    loop: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
    on: {
        init: function () {
            swiperAnimation.init(this).animate();
        },
        slideChange: function () {
            swiperAnimation.init(this).animate();
        }
    }
});

new Swiper('.images-swiper', {
    slidesPerView: 1,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    }
});

const observer = new IntersectionObserver(entries => {
    entries.forEach(function (entry) {
        if (entry.isIntersecting) {
            let lazyImage = entry.target;
            lazyImage.classList.add('loaded');
            lazyImage.src = lazyImage.dataset.src;
            lazyImage.classList.remove("lazy");
            observer.unobserve(lazyImage);
        }
    });
}, {
    rootMargin: "50px"
});

window.onload = function () {
    ma5menu({
        menu: '.ma5-menu',
        activeClass: 'active',
        footer: '#ma5menu-tools',
        position: 'left',
        closeOnBodyClick: true
    });

    AOS.init();

    let srcItems = document.querySelectorAll('[data-src]');
    srcItems.forEach(box => {
        observer.observe(box);
    });

    let scrollToTop = document.querySelector('.scroll-to-top');

    window.onscroll = function () {
        if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
            scrollToTop.style.opacity = 1;
            scrollToTop.style.visibility = "visible";
        } else {
            scrollToTop.style.opacity = 0;
            scrollToTop.style.visibility = "hidden";
        }
    }

    scrollToTop.onclick = function () {
        window.scrollTo(0, 0)
    }

    $('.close-sidebar, .widget-backdrop').click(function () {
        $('.mobile-menu-btn').trigger('click');
    });

    $('.mobile-menu-btn').on('click', function () {
        if (!$(this).hasClass('active')) {
            $(this).addClass('active');
            $(this).find('svg').html('<use xlink:href="/assets/images/sprite.svg#times"></use>')
            $(this).parents('aside').addClass('sidebar-active');
        } else {
            $(this).removeClass('active');
            $(this).find('svg').html('<use xlink:href="/assets/images/sprite.svg#bars-light"></use>')
            $(this).parents('aside').removeClass('sidebar-active');
        }
    });
}

function CreateModal(dt) {
    let id = '#kvkk-' + dt.id;
    let element = document.getElementById(id);
    if (typeof (element) != 'undefined' && element != null) {
        $(id).find("#ModalLabel").text(dt.title);
        $(id).find("#ModalContent").html(dt.description);
    } else {
        $('body').append('<div class="modal fade" id="' + id + '" tabindex="-1">\n' +
            '  <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl kvkk-modal">\n' +
            '    <div class="modal-content">\n' +
            '      <div class="modal-header">\n' +
            '        <h5 class="modal-title" id="ModalLabel">' + dt.title + '</h5>\n' +
            '        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Kapat"></button>\n' +
            '      </div>\n' +
            '      <div class="modal-body" id="ModalContent"><div class="page-content">\n' + dt.description +
            '      </div>\n' +
            '      </div>\n' +
            '      <div class="modal-footer">\n' +
            '        <button type="button" class="btn btn-secondary text-white" data-bs-dismiss="modal">' + window.lang.closeButton + '</button>\n' +
            '      </div>\n' +
            '    </div>\n' +
            '  </div>\n' +
            '</div>');
        element = document.getElementById(id);
    }
    return new Modal(element, {});
}

let forms = document.querySelectorAll('form[method="post"]');
if (forms.length > 0) {
    forms.forEach(function (el) {
        el.addEventListener('submit', function (e) {
            e.preventDefault();
            let form = this;
            form.querySelector('button[type="submit"]').setAttribute('disabled', 'disabled');
            Swal.fire({
                title: window.lang.formSendingText,
                icon: 'info',
            }).then(() => {
            });

            fetch(this.getAttribute('action'), {
                method: 'POST',
                body: new FormData(this),
                cache: 'no-cache',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Data-Type': 'application/json'
                }
            }).then((response) => response.json())
                .then((data) => {
                    if (data.status === 'success') {
                        Swal.fire({
                            icon: 'success',
                            title: data.title,
                            text: data.message,
                        }).then(r => {
                        });
                        form.reset();
                        if (el.parentElement.classList.contains('modal-body'))
                            el.parentElement.parentElement.querySelector('.btn-close').click();

                        if (document.querySelector('.wrapper .file'))
                            resetFileInput(document.querySelector('.wrapper .file'));

                        $(".select2").val("").trigger("change");
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: data.title,
                            text: data.message,
                        }).then(r => {
                        })
                    }
                    form.querySelector('button[type="submit"]').removeAttribute('disabled');
                }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: error.title,
                    text: error.message,
                }).then(r => {
                });
                form.querySelector('button[type="submit"]').removeAttribute('disabled');
            });
        })
    });
}

if (document.querySelectorAll('.kvkk')) {
    document.querySelectorAll('.kvkk').forEach(function (el) {
        el.addEventListener('click', function () {
            fetch(window.siteurl + 'kvkk', {
                method: 'POST',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Data-Type': 'application/json'
                }
            }).then((response) => response.json())
                .then((data) => {
                    const myModal = CreateModal(data);
                    myModal.show();
                }).catch((error) => {
                console.error('Error:', error);
                Swal.fire({
                    icon: 'error',
                    title: window.lang.formError
                }).then(() => {
                })
            });
        });
    })
}

if (document.querySelector(".search-btn")) {
    document.querySelector(".search-btn").addEventListener('click', function () {
        setTimeout(function () {
            document.querySelector('#searchInput').focus();
        }, 500)
    })
}

$('.whatsapp_bubble').on('click', function () {
    if ($('.whatsapp_pp').hasClass('active')) {
        $('.whatsapp_pp').removeClass('active')
    } else {
        $('.whatsapp_pp').addClass('active')
    }
});

$('.whatsapp_pp .close_pp').on('click', function () {
    $('.whatsapp_pp').removeClass('active')
});

document.querySelectorAll('.locations li').forEach(function (location) {
    location.addEventListener('click', function () {
        let id = location.dataset.id;

        document.querySelectorAll('.locations li').forEach(function (location) {
            location.classList.remove('active');
        })
        location.classList.add('active');

        document.querySelectorAll('[data-country-id]').forEach(function (item) {
            item.classList.remove('active');
        })

        document.querySelectorAll(`[data-country-id="${id}"]`).forEach(function (item) {
            item.classList.add('active');
        })
    })
});
if (document.querySelector("#select-date")) {
    document.querySelector("#select-date").addEventListener('change', function (option) {

        let url = new URL(window.location.href);
        if (option.target.value != 1) {
            url.searchParams.set('date', option.target.value);
        } else {
            url.searchParams.delete('date');
        }
        window.location.href = url.href;
    });
}

const resetFileInput = function (ths) {
    $(ths).removeClass('full');
    $(ths).find('input[type="file"]').val('');
    $(ths).find('.file-name').html("Upload File");
    $(ths).find('.check').html("<svg class=\"cvzicon\"><use xlink:href=\"/assets/images/sprite.svg#upload\"></use></svg>");
}

$('.upload-file').on('change', function () {
    let $this = $(this);
    let $file = $this[0].files[0];
    let $fileName = $file.name;
    let $fileSize = $file.size;
    let $fileType = $file.type;
    let $fileExt = $fileName.split('.').pop();
    let $fileExts = ['jpg', 'jpeg', 'png', 'gif', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'zip', 'rar'];
    let $fileMaxSize = 10485760;
    let $fileMaxSizeText = '10 MB';

    if ($.inArray($fileExt, $fileExts) == -1) {
        resetFileInput($($this).closest('.file'));

        Swal.fire({
            title: "Error",
            text: "File type is not allowed. Allowed file types are: " + $fileExts.join(', '),
            icon: 'error',
            confirmButtonText: "Close"
        }).then(r => function () {
        });

        return false;
    }

    if ($fileSize > $fileMaxSize) {
        resetFileInput($($this).closest('.file'));

        Swal.fire({
            title: "Error",
            text: "File size is too large. Maximum file size is " + $fileMaxSizeText,
            icon: 'error',
            confirmButtonText: "Close"
        }).then(r => function () {
        });

        return false;
    }

    $($this).closest('.file').addClass('full');
    $($this).closest('.file').find('a').html('<div class="d-flex align-items-center justify-content-center"><a class="check"></a></div>');
    $($this).closest('.file').find('.file-name').text(`${$fileName}`);
    $($this).closest('.file').find('.check').html(`<svg class="cvzicon"><use xlink:href="/assets/images/sprite.svg#times"></use></svg>`);


});

// $('.file').hover(function () {
//     $(".check").addClass('hover');
// },function (){
//     $(".check").removeClass('hover');
// });

$('.fileselect').on('click', function () {
    if ($(this).parent().hasClass('full')) {
        resetFileInput($(this).parent());
    } else {
        $(this).parent().find('.upload-file').trigger('click');
    }
});