import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

var mesh, renderer, scene, camera, controls, theMesh ;

function unleashTheGlobe() {
    var site = document.getElementById('body');
    var theGlobe = document.getElementsByClassName("theGlobe")[0];

    var $cameraZIndex = 2.8;
    if ($(window).width() < 1281)
        $cameraZIndex = 3.6;

    if ($(window).width() < 1101)
        $cameraZIndex = 4.5;

    if ($(window).width() < 993)
        $cameraZIndex = 2.8;

    if ($(window).width() < 769)
        $cameraZIndex = 3.3;

    if ($(window).width() < 550)
        $cameraZIndex = 2.8;


    if (theGlobe) {
        scene = new THREE.Scene,
            (camera = new THREE.PerspectiveCamera(45,theGlobe.offsetWidth / theGlobe.offsetHeight,.1,2000)).position.set(0, 0, $cameraZIndex),
            (renderer = new THREE.WebGLRenderer({alpha: !0})).setClearColor(0, 0),
            renderer.setSize(theGlobe.offsetWidth, theGlobe.offsetHeight),
            theGlobe.appendChild(renderer.domElement);
        var sahneIsigi = new THREE.HemisphereLight(16777215,16777215,1);
        scene.add(sahneIsigi);
        var yonluIsik = new THREE.DirectionalLight(16777215,.5);
        yonluIsik.position.set(0, 4, 4);
        scene.add(yonluIsik);
        var color = new THREE.Color(255, 0, 0);
        scene.add(color);


        var dunya = new THREE.SphereGeometry(1,40,30);
        var dunyaTexture = new THREE.MeshPhongMaterial({
            map: (new THREE.TextureLoader).load("/assets/images/global_map.png")
        });


        theMesh = new THREE.Mesh(dunya,dunyaTexture),
            scene.add(theMesh),
            (controls = new OrbitControls(camera,renderer.domElement)).enableDamping = !0,
            controls.enableZoom = !1,
            window.addEventListener("resize", o, !1),
            theMesh.rotation.y=10.3
        theMesh.rotation.x=.3
        i()

    }
    function i() {
        requestAnimationFrame(i),
            renderer.render(scene, camera),
            controls.update()
        //theMesh.rotation.y += .001,
    }
    function o() {
        camera.aspect = theGlobe.offsetWidth / theGlobe.offsetHeight,
            camera.updateProjectionMatrix(),
            renderer.setSize(theGlobe.offsetWidth, theGlobe.offsetHeight),
            console.log(theMesh.rotation)

    }

}

unleashTheGlobe()
