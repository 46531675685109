import Cookies from "js-cookie";



const CevizCookieConsent = function () {

    jQuery.fn.exists = function () {
        return this.length > 0;
    }

    const prefix = 'ceviz_cookie';
    const wrapper = document.getElementById('CevizCookieSystem');


    const tabs = () => {
        wrapper.querySelectorAll(`.${prefix}_list_item`).forEach(function (e){
            e.addEventListener('click', function (el){
                document.querySelector(`.${prefix}_list_item_active`).classList.remove(`${prefix}_list_item_active`)
                document.querySelector(`.${prefix}_tab_content_active`).classList.remove(`${prefix}_tab_content_active`)

                let tab = e.getAttribute('data-tab');
                e.classList.add(`${prefix}_list_item_active`);
                wrapper.querySelector(tab).classList.add(`${prefix}_tab_content_active`);


            })
        })
    }

    const actions = () => {
        document.querySelectorAll(`.${prefix}_renew`).forEach(function (el){
            el.addEventListener('click', function (){
                Cookies.remove('cevizCookieConsent');
                window.location.reload();
            })
        });
        document.querySelectorAll(`.${prefix}_action_reject`).forEach(function (el){
            el.addEventListener('click', function (){
                Cookies.set('cevizCookieConsent', "reject", {expires: 30});
                formPost();
                wrapper.classList.remove(`${prefix}_dialog_active`);

                let consent = document.querySelectorAll(`.${prefix}_consent`)
                consent.forEach(function (e){
                    e.remove();
                })
            })
        })
        document.querySelectorAll(`.${prefix}_action_accept`).forEach(function (el){
            el.addEventListener('click', function (){
                Cookies.set('cevizCookieConsent', "accept", {expires: 30});
                formPost();
                wrapper.classList.remove(`${prefix}_dialog_active`)

                let consent = document.querySelectorAll(`.${prefix}_consent`)
                consent.forEach(function (e){
                    e.remove();
                })
            })
        })

        document.querySelectorAll(`.${prefix}_action_customize`).forEach(function (el){
            el.addEventListener('click', function (){
                document.querySelector(`.${prefix}_list_item_active`).classList.remove(`${prefix}_list_item_active`)
                document.querySelector(`.${prefix}_tab_content_active`).classList.remove(`${prefix}_tab_content_active`)


                let consent = document.querySelectorAll(`.${prefix}_consent`)
                consent.forEach(function (e){
                    e.remove();
                })


                let e = wrapper.querySelector(`.${prefix}_list_item_customize`)
                let tab = e.getAttribute('data-tab');
                e.classList.add(`${prefix}_list_item_active`);
                wrapper.querySelector(tab).classList.add(`${prefix}_tab_content_active`);
                wrapper.classList.add(`${prefix}_dialog_active`)
            })
        })

        wrapper.querySelector(`.${prefix}_action_selection`).addEventListener('click', function (e){
            let list = [];
            wrapper.querySelectorAll(`.${prefix}_card_item`).forEach(function (e){
                if (e.querySelector('input').checked){
                    list.push(e.querySelector('input').getAttribute('name'))
                }
            })
            Cookies.set('cevizCookieConsent', JSON.stringify(list), {expires: 30});
            formPost();
            wrapper.classList.remove(`${prefix}_dialog_active`)
        })

    }

    const formPost = () => {
        let form = wrapper.querySelector('form.ceviz_cookie_form');
        fetch(form.getAttribute('action'), {
            method: 'POST',
            body: new FormData(form),
            cache: 'no-cache',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Data-Type': 'application/json'
            }
        }).then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                } else {
                }
            }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: error.title,
                text: error.message,
            }).then(r => {
            });
        });

    }

    return {
        init: function() {
            if(document.getElementById('CevizCookieSystem') == 'null')
                return;

            if (wrapper == null) return false;

            tabs();
            actions();
        }
    };
}();


jQuery(document).ready(function() {
    CevizCookieConsent.init();
});